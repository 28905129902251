import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageVideo,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper'; //helper for background css on other components
import {
  HeroBanner,
  EventDetails,
  RichText,
  FooterCta,
  Undefined,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageVideoProps {
  data?: ContentfulPageVideo;
}
const PageVideo: React.FC<PageVideoProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data.heroBanner &&
        wrapBackground(
          data.heroBanner,
          <HeroBanner data={data.heroBanner as ContentfulEntry} />,
        )}

      {data.video && <EventDetails data={data.video} />}

      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;

        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_RICH_TEXT:
            element = <RichText key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}
      {data?.footerCta && <FooterCta data={data.footerCta} />}
    </Container>
  );
};
export default PageVideo;
